import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TvfAuthService, User } from 'src/app/service/tvf-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  /* member variables */
  validateForm: FormGroup;

  /* constructors */
  constructor(
    private tvfAuth: TvfAuthService,
    private fb: FormBuilder,
    private router: Router
  ) { }


  /* methods */
  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });

    this.tvfAuth.currentUser.subscribe( (user: User) => {
      if (user != null) {
        this.router.navigate(['/epg/tile']);
      }
    });
  }

  submitForm(): void {
    for (const i of Object.keys(this.validateForm.controls)) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    this.tvfAuth.login(
      this.validateForm.controls.userName.value,
      this.validateForm.controls.password.value
    );
  }

}
