import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthorizeService, Configuration, XAuthTokenResponse } from 'tvf-rest-client';


export interface User {
  name: string;
  token?: string;
}
@Injectable({
  providedIn: 'root'
})
export class TvfAuthService {

  /* constants */
  public static readonly CUR_USER_KEY = 'currentUser';

  /* member variables */
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  defaultTokenCreated = new EventEmitter<string>();
  accessToken: string = null;


  /* constructors */
  constructor(
    private configuration: Configuration,
    private authorizeService: AuthorizeService
  ) {

    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem(TvfAuthService.CUR_USER_KEY))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }


  /* methods */
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * Checks if the user is authenticated.
   *
   * @returns boolean
   */
  isAuthenticated(): boolean {
    return this.currentUserSubject.value != null;
  }

  checkAutoLogin(): boolean {
    let blRetvalue = false;
    if (
      localStorage.getItem(TvfAuthService.CUR_USER_KEY) &&
      this.configuration.apiKeys === undefined
    ) {
      const user: User = JSON.parse(localStorage.getItem(TvfAuthService.CUR_USER_KEY));

      this.accessToken = user.token;

      // for the inteceptor
      this.configuration.apiKeys = {
        Authorization: 'Bearer ' + user.token
      };
      this.currentUserSubject.next(user);
      this.defaultTokenCreated.emit(user.token);
      blRetvalue = true;
    }

    return blRetvalue;
  }

  login(
    username: string,
    password: string
  ): void {
    this.authorizeService.authorizeForm(
      'password',
      '3240932749082389423894',       // :TODO: Fingerprint of device
      'cloud:tvfactory-spare-gmbh_ios_device',
      username,
      password,
      'iPhone',
      'iOS',
      '11.4',
      '1.0.0',
      'tvf-cl'
    ).subscribe((response: XAuthTokenResponse) => {
      if (response && response.data) {
        const user: User = {
          name: username,
          token: response.data
        };
        this.accessToken = response.data;

        // for the inteceptor
        this.configuration.apiKeys = {
          Authorization: 'Bearer ' + user.token
        };

        localStorage.setItem(TvfAuthService.CUR_USER_KEY, JSON.stringify(user));
        this.currentUserSubject.next(user);

        this.defaultTokenCreated.emit(response.data);
      } else {
        // throw error
      }
    });
  }

  logout(): void {
    localStorage.removeItem(TvfAuthService.CUR_USER_KEY);
    this.currentUserSubject.next(null);
  }
}
