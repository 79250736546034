/**
 * @author Johann Kowalski (traal-devel) <devel@traal.ch>
 */

 import { 
  AfterViewChecked, 
  Component, 
  ElementRef, 
  OnInit, 
  ViewChild 
} from '@angular/core';
import { interval, Observable } from 'rxjs';
import { TimeDifferenceWidthPipe } from 'src/app/shared/pipe/time-difference-width.pipe';
import { EpgService } from 'src/app/service/epg.service';
import { TvfAuthService } from 'src/app/service/tvf-auth.service';
import { 
  AssetItem, 
  AssetsListResponse, 
  ChannelProgram, 
  ChannelStreamResponse, 
  DataStreamResponse, 
  EpgListResponse 
} from 'tvf-rest-client';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { HlsStreamPipe } from 'src/app/shared/pipe/hls-stream.pipe';
import * as Plyr from 'plyr';
import * as Hls from 'hls.js';


// Map.prototype.insertAtIndex = function(index, key, value, map){
//   const arr = Array.from(map);
//   arr.splice(index, 0, [key, value]);
//   return new Map(arr);
// };

enum FlagChannelMode { INSERT, UPDATE }

@Component({
  selector: 'app-epg-grid',
  templateUrl: './epg-grid.component.html',
  styleUrls: ['./epg-grid.component.css']
})
export class EpgGridComponent implements OnInit, AfterViewChecked {


  /* member variables */
  private source = interval(30000);
  private blInitViewPort = false;

  faPlay = faPlay;

  detailIndex = -1;
  showVideo = false;

  currentPosition = -10;
  currentLabel = '';
  private offset = -144; // 144;


  @ViewChild('epgWrapper', { static: false }) epgWrapperRef: ElementRef;


  ele = null;
  pos = { top: 0, left: 0, x: 0, y: 0 };

  dataHourLabel = [
    '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00',
    '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
    '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '00:00', '01:00'
  ];

  private m_offsetDST:number = 0;
  
  utcOffset = 'UTC+01:00';
  channelMap = {};
  epgDataList = [];
  currentChannelList = [];
  epgCurrentList: ChannelProgram[];

  player: Plyr = null;

  hls: Hls = null;



  /* constructors */
  constructor(
    private tvfAuthService: TvfAuthService,
    private epgService: EpgService,
    private hlsStreamPipe: HlsStreamPipe,
  ) { 
    if(this.epgService.isCurrentDateDST()) {
      this.m_offsetDST = 60 * 60 * 1000;
      this.utcOffset = 'UTC+02:00';
    }
  }


  /* methods */
  ngOnInit(): void {
    // ensure that access token is present in the http headers
    this.tvfAuthService.checkAutoLogin();

    this.setCurrentPosition();
    this.source.subscribe((value: number) => {
      this.setCurrentPosition();
    });

    this.initEpgGrid();
    this.initChannelList();
  }

  /**
   * Workaound: we cant not use ngAfterView.
   */
  ngAfterViewChecked(): void {
    if (!this.blInitViewPort) {
      this.initViewPort();
      this.blInitViewPort = true;
    }
  }

  initViewPort(): void {
    this.epgWrapperRef.nativeElement.scrollTo({
      left: this.currentPosition - (TimeDifferenceWidthPipe.VIEWPORT_WIDTH / 2), behavior: 'smooth'
    });
  }

  setCurrentPosition(): void {
    const currentDate = new Date();
    const currentMinutes = new Date(currentDate.getTime() - this.m_offsetDST).getHours() * 60 + currentDate.getMinutes() ;
    this.updateCurrentTime(currentDate);
    this.currentPosition = ((currentMinutes / TimeDifferenceWidthPipe.SMALL_UNIT_IN_MIN)
      * TimeDifferenceWidthPipe.SMALL_UNIT_IN_PX + this.offset);

    // for (let obj of this.currentChannelList) {
    //   this.flagAsCurrentChannel(currentDate, obj, FlagChannelMode.UPDATE);
    // }

  }

  updateCurrentTime(currentDate: Date): void {
    const minutesLabel = (currentDate.getMinutes() < 10 ? '0' : '') + currentDate.getMinutes();
    this.currentLabel = (new Date(currentDate.getTime())).getHours() + ':' + minutesLabel;

  }

  private initEpgGrid(): void {
    const dCurrentDate = new Date();
    const myFormattedDate = this.epgService.formatDateShort(dCurrentDate);
    this.epgService
      .getEpgOverviewList(dCurrentDate)
      .subscribe((retValue: EpgListResponse) => {
        const tmpEpgMap = retValue.data.reduce((map, obj) => {
          if (map[obj.live_id] == null) {
            map[obj.live_id] = [];
          }
          if (!obj.start_time.startsWith(myFormattedDate)) {
            obj.start_time = myFormattedDate + 'T00:00:00Z';
          }

          if (!obj.end_time.startsWith(myFormattedDate)) {
            obj.end_time = myFormattedDate + 'T23:59:59Z';
          }

          this.flagAsCurrentChannel(dCurrentDate, obj, FlagChannelMode.INSERT);

          map[obj.live_id].push(obj);
          return map;
        }, {} as Map<number, ChannelProgram>);

        const tmpEpgArray = Object.values(tmpEpgMap);
        tmpEpgArray.sort((a: Array<ChannelProgram>, b: Array<ChannelProgram>) => {
          return this.channelMap[a[0].live_id].lcn < this.channelMap[b[0].live_id].lcn ? -1 : 1;
        });

        this.epgDataList = Object.values(tmpEpgArray);
      });

  }

  flagAsCurrentChannel(
    dCurrentDate: Date,
    epgProgram: ChannelProgram,
    mode: FlagChannelMode
  ): void {
    const dStartTime = new Date(epgProgram.start_time);
    const dEndTime = new Date(epgProgram.end_time);

    const now = dCurrentDate.getTime();
    if (now >= dStartTime.getTime() && now < dEndTime.getTime()) {
      this.currentChannelList.push(epgProgram);
    }
    //  else if (FlagChannelMode.UPDATE === mode) {
    //   // // remove from current channel list
    //   // const idx = this.currentChannelList.indexOf(epgProgram);
    //   // this.currentChannelList.splice(idx, idx);
    //   // console.log("Remove: ", idx);

    //   // const arrEpgChannel = this.dataEpgMap[epgProgram.live_id];
    //   // const newIdx = arrEpgChannel.indexOf(epgProgram) + 1;
    //   // console.log("New: ", idx);
    //   // if (newIdx < arrEpgChannel.length) {
    //   //   this.currentChannelList.push(arrEpgChannel[newIdx]);
    //   // }
    // } else {
    // }
  }


  initChannelList(): void {
    this.epgService.getChannelList().subscribe((retValue: AssetsListResponse) => {
      this.channelMap = retValue.data.reduce((map, obj) => {
        map[obj.id] = obj;
        return map;
      }, {});
    });
  }

  showChannel(
    index: number,
    epgChannel: ChannelProgram
  ): void {
    const tmpRef = this.epgDataList[index];

    // delete detail
    if (this.detailIndex > -1) {
      this.epgDataList.splice(this.detailIndex, 1);
      index = index - 1;
    }
    console.log('show-channel', index);

    this.detailIndex = index + 1;
    this.epgDataList.splice(this.detailIndex, 0, tmpRef);
    this.epgDataList = [
      ...this.epgDataList
    ];

    // Currently Live or catchup ... show video stream.
    if (
      epgChannel.catchup === true ||
      this.currentChannelList.indexOf(epgChannel) !== -1
    ) {
      // Workaround: Wait, till video element is added to dom tree.
      setTimeout(() => {
        const video = document.querySelector('video') as HTMLVideoElement;
        const epgChannels = this.epgDataList[this.detailIndex];
        const idx = epgChannels.indexOf(epgChannel);
        const channel = this.channelMap[epgChannels[idx].live_id];
        this.initPlyr(
          channel,
          epgChannel,
          video, 
        );
      });
      this.showVideo = true;
    } else {
      this.showVideo = false;
    }
    this.initEpg(epgChannel);
  }

  closeChannel(): void {
    this.closeStream();

    this.epgDataList.splice(this.detailIndex, 1);
    this.detailIndex = -1;
  }

  closeStream(): void {
    console.log('HLS Stream:', this.hls);

    if (this.player != null) {
      this.player.stop();
      this.player.destroy();
      this.player = null;
    }

    if (this.hls != null) {
      console.log("close hls stream");
      this.hls.stopLoad();
      this.hls.destroy();
      this.hls = null
    }
  }

  private initEpg(
    epgChannel: ChannelProgram
  ): void {
    this.epgService
      .getLiveEventAt(
        epgChannel.live_id,
        epgChannel.start_time
      )
      .subscribe((value: EpgListResponse) => {
        this.epgCurrentList = value.data;
      });
  }

  private initPlyr(
    assetItem: AssetItem,
    epgChannel: ChannelProgram,
    video: HTMLVideoElement
  ): void {
    console.log("AsestItem: ", assetItem);

    var controlsToShow = [
      'play-large', // The large play button in the center
      'play', // Play/pause playback
      // 'duration', // The full duration of the media
      'volume', // Volume control
      'mute', // Toggle mute
      'captions', // Toggle captions
      'settings', // Settings menu
      'fullscreen', // Toggle fullscreen
    ];

    if (epgChannel.catchup === true) {
      controlsToShow.splice(4,0,'progress');
      controlsToShow.splice(5,0,'duration');
      controlsToShow.splice(2,0, 'fast-forward');
    }

    this.closeStream();
    this.player = new Plyr(video, {
      captions: {
        active: true,
        update: true,
        language: 'en'
      },
      controls: controlsToShow
    });

    if (!Hls.isSupported()) {
      alert('HLS is NOT supported');
    } else {
      this.subcribeToStream(
        assetItem,
        epgChannel
      ).subscribe((response: ChannelStreamResponse) => {
        const hlsOptions = { xhrSetup: xhr => { } };
        this.hls = new Hls(hlsOptions);
        this.hls.loadSource(response.data);
        this.hls.attachMedia(video);
        // this.hls.on(Hls.Events.MANIFEST_PARSED, function(event,data) {
        //   console.log('levels', this.hls.levels); // this is the quality level  defined
        //   // playerOptions.quality = {
        //   //   default: hls.levels[hls.levels.length - 1].height,
        //   //   options: hls.levels.map((level) => level.height),
        //   //   forced: true,
        //   // }
        // });
        // Handle changing captions
        // this.player.on('languagechange', () => {
        //   // Caption support is still flaky. See: https://github.com/sampotts/plyr/issues/994
        //   setTimeout(() => this.hls.subtitleTrack = this.player.currentTrack, 50);
        // });
      });
    }  
  }
    
  private subcribeToStream(
      assetItem: AssetItem,
      epgChannel: ChannelProgram
  ): Observable<DataStreamResponse> {
    if (epgChannel.catchup === true) {
      return this.epgService.getCatchupStream(epgChannel.id);
    } else {
      const rHlsStream = this.hlsStreamPipe.transform(assetItem.resources);
      return this.epgService.getChannelStream(assetItem.id, rHlsStream.id);
    }
  }

  // // https://github.com/phuoc-ng/html-dom/blob/master/demo/drag-to-scroll/index.html
  // mouseDownHandler(e): void {
  //   console.log('mouse-down-handler');
  //   this.ele = document.getElementById('epgWrapper');
  //   this.ele.style.cursor = 'grabbing';
  //   this.ele.style.userSelect = 'none';

  //   this.pos = {
  //     left: this.ele.scrollLeft,
  //     top: this.ele.scrollTop,
  //     // Get the current mouse position
  //     x: e.clientX,
  //     y: e.clientY,
  //   };

  //   document.addEventListener('mousemove', this.mouseMoveHandler);
  //   document.addEventListener('mouseup', this.mouseUpHandler);
  // }

  // mouseMoveHandler(e): void {
  //   console.log('mouse-move-handler', this.pos);
  //   this.ele = document.getElementById('epgWrapper');
  //   // How far the mouse has been moved
  //   const dx = e.clientX - this.pos.x;
  //   const dy = e.clientY - this.pos.y;

  //   // Scroll the element
  //   this.ele.scrollTop = this.pos.top - dy;
  //   this.ele.scrollLeft = this.pos.left - dx;
  // }

  // mouseUpHandler(e): void {
  //   console.log('mouse-up-handler');
  //   this.ele = document.getElementById('epgWrapper');
  //   this.ele.style.cursor = 'grab';
  //   this.ele.style.removeProperty('user-select');

  //   document.removeEventListener('mousemove', this.mouseMoveHandler);
  //   document.removeEventListener('mouseup', this.mouseUpHandler);
  // }
}
