<div class="view">
  <div id="epgWrapper" class="wrapper" #epgWrapper>
    
    <div id="epgContainer" class="container" style="margin:30px 0px; width:6912px;">
      <div class="current-time-line" [style]="{left: currentPosition + 'px'}">
        <div>{{currentLabel}}</div>
      </div>
      <div>
        <div style='width:144px; display:inline-block'>
        </div>
        <div *ngFor="let epgChannel of dataHourLabel" style='width:288px; display:inline-block'>
          <nz-card style="background: transparent; border: 1px solid #eee; border-width: 0px 0px 1px 1px">
            <h1 style="color: white">{{epgChannel}}</h1>
          </nz-card>
        </div>
      </div>
      <div 
        style="position:relative;"
        *ngFor="let epgChannels of epgDataList; index as i" 
      >
        <div>
          <div 
              style="width:144px; display:inline-block;position:sticky;left:0px; z-Index:12">
            <div style="padding: 2px 0px;">
              <nz-card class="ant-card ant-card-bordered" 
                       style="cursor: pointer;"
                       (click)="playLiveChannel(i)">
                <div class="ant-card-body" >
                  <div class=""
                    style="padding: 2px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;height:50px;max-height:50px;"
                    *ngIf="channelMap[epgChannels[0].live_id]">
                    <img 
                      c-img-src 
                      dsrc="assets/no-channel-logo.png"
                      [rsrc]="channelMap[epgChannels[0].live_id].resources | channelThumb" 
                      style="width:auto; height:100%"
                    />
                    <span>{{channelMap[epgChannels[0].live_id].name}}</span>
                  </div>
                </div>
              </nz-card>
            </div>
          </div> 
        
          <div 
            [style]="{'width': epgChannel | timeDifferenceWidth, 'display': 'inline-block', zIndex:0}"
            title="{{epgChannel.start_time | date:'HH:mm': utcOffset :'de-ch'}} - {{epgChannel.name}}"
            *ngFor="let epgChannel of epgChannels"
          >
            <div 
              style="padding:2px;" 
            >
              <nz-card
              (click)="showChannel(i, epgChannel)"
              [class]="{'epgprogram-current': channelLiveList.indexOf(epgChannel) !== -1}"
              >
                <div class="epgprogram"
                  style="padding:2px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                  <span style="font-weight:bolder">
                    {{epgChannel.start_time | date:'HH:mm': utcOffset :'de-ch'}}
                  </span>
                  <a routerLink="/epg/grid" title="EPG-Grid"
                      *ngIf="epgChannel.catchup === true || channelLiveList.indexOf(epgChannel) !== -1">
                    &nbsp;<fa-icon [icon]="faPlay" style="font-size:0.8em; color: rgba(255, 255, 255, 0.85);"></fa-icon>
                  </a>
                  <br />
                  {{epgChannel.name}}
                </div>
              </nz-card>
            </div>
          </div>
        </div>



        <div [hidden]="currentLiveId !== epgChannels[0].live_id">
          <div style="width: 144px; display: inline-block; position: sticky; left: 0px; z-index: 12;">
            <div style="padding: 0px 0px 0px 144px;width:1200px;position: relative"><!--start-->
            
              <div 
              style="padding:2px;" 
              >
                <nz-card>
                  <div nz-row nzJustify="left" nzAlign="top" class="app-icons">
                    <div nz-col [nzSpan]="10">
                      <div class="video-container">
                        <video 
                          id="video_{{i}}"
                          controls 
                          crossorigin 
                          playsinline 
                          [hidden]="showVideo === false"
                        >
                          <source src="" />
                        </video>
                        <div 
                          style="background-color:black; width:100%; height:220px"
                          [hidden]="showVideo === true">
                        &nbsp;
                      </div>
                      </div>
                    </div>
                    <div nz-col [nzSpan]="14">
                      <div style="margin:14px;" *ngIf="detailIndex === i">
                        <h2 style="font-weight:bolder">
                          {{epgDataList[detailIndex].name}}
                        </h2>
                        <div 
                          *ngIf="epgCurrentList && epgCurrentList[0];else noData">
                          <h2 stlye="font-weight:bold">
                            {{channelMap[epgChannels[0].live_id].name}} / {{epgCurrentList[0].start_time | date:'H:mm'}} &ndash; {{epgCurrentList[0].end_time | date:'H:mm'}}
                          </h2>
                          <div >
                            <h3 style="font-weight:bold">
                              {{epgCurrentList[0].name}}
                            </h3>
                            <h4 style="font-weight:lighter">
                              {{epgCurrentList[0].episode_name}}
                            </h4>
                            <p style="white-space: normal;">
                              {{epgCurrentList[0].description}}
                            </p>
                          </div>
                        </div>
                        <ng-template #noData>
                          <div class="epgBody">
                            <h2 stlye="font-weight:bold">
                              {{channelMap[epgChannels[0].live_id].name}}
                            </h2>
                            <div style="max-height:150px;overflow: auto;">
                              No Epg-Data found!
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>

                </nz-card>
                <i 
                  nz-icon 
                  nzType="close" 
                  nzTheme="outline" 
                  style="position:absolute; right:16px; top:16px;font-size:1.5em;cursor: pointer;"
                  (click)="closeChannel()"
                >
                </i>
              </div>
            
            
            </div> <!-- end-->
          </div>
        </div>


      </div>
    </div>
  
  </div>
</div>