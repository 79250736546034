import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorPageComponent implements OnInit {


  /* member variables */
  errorMessage: string;


  /* constructors */
  constructor(
    private route: ActivatedRoute
  ) { }


  /* methods */
  ngOnInit(): void {
    // this.errorMessage = this.route.snapshot.data['message'];
    this.route.data.subscribe(
      (data: Data) => {
        this.errorMessage = data.message;
      }
    );
  }
}
