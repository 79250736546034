import { Component, Input, OnInit } from '@angular/core';
import { ChannelProgram } from 'tvf-rest-client';

@Component({
  selector: '.app-epg-tile-col',
  templateUrl: './epg-tile-col.component.html',
  styleUrls: ['./epg-tile-col.component.css']
})
export class EpgTileColComponent implements OnInit {


  /* member variables */
  @Input() channelProgram: ChannelProgram;
  @Input() title: string;


  /* methods */
  constructor() { }

  ngOnInit(): void {
  }

}
