import { Pipe, PipeTransform } from '@angular/core';
import { ChannelProgram, ChannelStreamResponse } from 'projects/tvf-rest-client/src';

@Pipe({
  name: 'timeDifferenceWidth'
})
export class TimeDifferenceWidthPipe implements PipeTransform {


  /* member variables */
  static readonly DAY_IN_MS: number = 24 * 60;
  static readonly VIRT_WIDTH: number = 6912;
  static readonly VIEWPORT_WIDTH: number = 1200;
  static readonly SMALL_UNIT_IN_MIN: number = 5;
  static readonly SMALL_UNIT_IN_PX: number = 24; //px


  /* methods */
  transform(value: ChannelProgram): string {
    const diffInMin =
      (new Date(value.end_time).getTime() - new Date(value.start_time).getTime())
      / (1000 * 60);
    const retValue =
      (diffInMin / TimeDifferenceWidthPipe.SMALL_UNIT_IN_MIN)
      * TimeDifferenceWidthPipe.SMALL_UNIT_IN_PX;
    return (retValue) + 'px';
  }

}
