<div class="epgBody"
  *ngIf="channelProgram;else noData">
  <h2 stlye="font-weight:bold">{{title}} - {{channelProgram.start_time | date:'H:mm'}}</h2>
  <div style="max-height:150px;overflow: auto;">
    <h3 style="font-weight:bold">{{channelProgram.name}}</h3>
    <h4 style="font-weight:lighter">{{channelProgram.episode_name}}</h4>
    <p>
      {{channelProgram.description}}
    </p>
  </div>
</div>
<ng-template #noData>
  <div class="epgBody">
    <h2 stlye="font-weight:bold">{{title}}</h2>
    <div style="max-height:150px;overflow: auto;">
      No Epg-Data found!
    </div>
  </div>
</ng-template>