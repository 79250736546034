import { Pipe, PipeTransform } from '@angular/core';
import { Resource } from 'tvf-rest-client';

@Pipe({
  name: 'hlsStream'
})
export class HlsStreamPipe implements PipeTransform {

  transform(value: Resource[]): Resource {
    return value.find(p =>
      // p.groupId === 'stream' // :BUG: ? is as group_id declared.
      p.state === 'deployed'
      && p.type === 'hls-live'
    );
  }

}
