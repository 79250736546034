/**
 * @author Johann Kowalski (traal-devel) <devel@traal.ch>
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';
import { FullLayoutComponent } from './layout/full-layout/full-layout.component';
import { EpgGridV2Component } from './page/epg/gridv2/epg-gridv2.component';
import { EpgTileRadioComponent } from './page/epg/tile-radio/epg-tile-radio.component';
import { EpgTileComponent } from './page/epg/tile/epg-tile.component';
import { ErrorPageComponent } from './page/error/error.component';
import { LoginComponent } from './page/login/login.component';
import { AuthGuard } from './service/auth.guard';
// import { FullLayoutComponent } from './layout/full-layout/full-layout.component';
// import { FULL_LAYOUT_ROUTES } from './shared/route/full-layout.routes';


const appRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent
      }
    ],
  },
  {
    path: 'epg',
    component: FullLayoutComponent,
    children: [
      {
        path: 'tile',
        component: EpgTileComponent,
      },
      {
        path: 'tile-radio',
        component: EpgTileRadioComponent,
      },
      {
        path: 'grid',
        component: EpgGridV2Component,
      },
      // {
      //   path: 'gridv2',
      //   component: EpgGridV2Component,
      // },
    ],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'not-found',
    component: ErrorPageComponent,
    data: { message: 'Page not found!' }
  },
  {
    path: '**',
    redirectTo: '/not-found'
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: false })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }