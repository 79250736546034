/**
 * @author Johann Kowalski (traal-devel) <devel@traal.ch>
 */

import { Component, OnInit } from '@angular/core';
import { TvfAuthService } from './service/tvf-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  /* constructors */
  constructor(
    private tvfAuthService: TvfAuthService
  ) { }

  /* methods */
  ngOnInit(): void {
    this.tvfAuthService.checkAutoLogin();
  }
}
