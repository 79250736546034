<div nz-row nzJustify="left" nzAlign="middle" class="app-icons">
  <div 
    *ngFor="let data of channelList;index as i"
    nz-col 
    [nzSpan]="detailIndex === i ? 24 : 3"
  >
    <nz-card
      *ngIf="detailIndex === i"
      style="margin:4px;" 
      class="channelDetail"
    >
      <div nz-row nzJustify="left" nzAlign="top" class="app-icons">
        <div nz-col [nzSpan]="10">
          <div class="video-container">
            <video 
              id="video_{{i}}"
              controls 
              crossorigin 
              playsinline 
              #videoPlyr
              >
            </video>
          </div>
        </div>
        <div nz-col [nzSpan]="14">
          <div style="margin:14px;">
            <h2 style="font-weight:bolder">
              {{channelList[detailIndex].name}}
            </h2>
            <table 
              style="width:100%"
              *ngIf="epgCurrentList"
            >
              <tbody>
                <tr>
                  <td 
                    class="app-epg-tile-col" 
                    [channelProgram]="epgCurrentList[0]"
                    [title]="'Now'"
                  ></td>
                  <td 
                    class="app-epg-tile-col" 
                    [channelProgram]="epgCurrentList[1]"
                    [title]="'Next'"
                  ></td>
                  <td 
                    class="app-epg-tile-col" 
                    [channelProgram]="epgCurrentList[2]"
                    [title]="'Later'"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <i 
        nz-icon 
        nzType="close" 
        nzTheme="outline" 
        style="position:absolute; right:16px; top:16px;font-size:1.5em;cursor: pointer;"
        (click)="closeChannel()"
      >
      </i>
    </nz-card>
    
    <nz-card 
      nzHoverable
      style="margin:4px;" 
      class="channel"
      (click)="showChannel(i)"
      *ngIf="detailIndex !== i"
      nzSize="default"
    >
      <div style="position:relative">
        <img 
          c-img-src 
          dsrc="assets/no-channel-logo.png"
          [rsrc]="data.resources | channelThumb" 
          style="max-width:100%;text-align:center;" 
        />
        <h4 style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
          {{data.name}}
        </h4>
      </div>
    </nz-card>
  </div>
</div>
