import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';

@Directive({
  selector: '[c-img-src]'
})
export class LoadImageDirectiveDirective implements OnInit {


  /* member variables */
  /**
   * The default-image we load (which is not time consuming)
   */
  @Input() 
  dsrc:string = 'assets/no-channel-logo.png';

  /**
   * The real-image we want (and can be time consuming)
   */
  @Input()
  rsrc:string = null;


  /* constructors */
  constructor(
    private renderer: Renderer2, 
    private el: ElementRef
  ) { 
  }
  
  /* methods */
  ngOnInit() {
    fromEvent(this.el.nativeElement, 'load')
      .pipe(first())
      .subscribe(() => {
        this.renderer.setAttribute(
          this.el.nativeElement,
          'src', this.rsrc
        )
      });
    this.renderer.setAttribute(
      this.el.nativeElement,
      'src', this.dsrc
    )
  }

}
