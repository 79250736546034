import { Pipe, PipeTransform } from '@angular/core';
import { Resource } from 'tvf-rest-client';

@Pipe({
  name: 'channelThumb'
})
export class ChannelThumbPipe implements PipeTransform {

  /* methods */
  transform(value: Resource[]): string {
    const tmp = value.find(p => p.state === 'deployed' && p.type === 'picture');
    return tmp && tmp.url ? tmp.url : 'NO_IMAGE';
  }

}
