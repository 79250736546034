<nz-layout class="app-layout">
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <app-portal-header></app-portal-header>
      </div>
    </nz-header>
    <nz-content >
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>