/**
 * @author Johann Kowalski (traal-devel) <devel@traal.ch>
 */

 import { 
  AfterViewChecked, 
  Component, 
  ElementRef, 
  NgZone, 
  OnDestroy, 
  OnInit, 
  ViewChild 
} from '@angular/core';
import { interval, Observable } from 'rxjs';
import { TimeDifferenceWidthPipe } from 'src/app/shared/pipe/time-difference-width.pipe';
import { EpgService } from 'src/app/service/epg.service';
import { TvfAuthService } from 'src/app/service/tvf-auth.service';
import { 
  AssetItem, 
  AssetsListResponse, 
  ChannelProgram, 
  DataStreamResponse, 
  EpgListResponse 
} from 'tvf-rest-client';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { HlsStreamPipe } from 'src/app/shared/pipe/hls-stream.pipe';
import * as Plyr from 'plyr';
import * as Hls from 'hls.js';

enum FlagChannelMode { INSERT, UPDATE }

@Component({
  selector: 'app-epg-gridv2',
  templateUrl: './epg-gridv2.component.html',
  styleUrls: ['./epg-gridv2.component.css']
})
export class EpgGridV2Component implements OnInit, OnDestroy, AfterViewChecked {


  /* member variables */
  private source = interval(30000);
  private blInitViewPort = false;

  faPlay = faPlay;

  currentPosition = -10;
  currentLabel = '';
  private offset = -144; // 144;


  @ViewChild('epgWrapper', { static: false }) epgWrapperRef: ElementRef;


  ele = null;
  pos = { top: 0, left: 0, x: 0, y: 0 };

  dataHourLabel = [
    '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00',
    '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
    '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '00:00', '01:00'
  ];

  private m_offsetDST:number = 0;
  
  utcOffset = 'UTC+01:00';
  channelMap = {};
  channelSordedIds = [];
  epgDataList = [];
  epgDataList$:Observable<ChannelProgram>;
  channelLiveList = [];
  epgCurrentList: ChannelProgram[];

  player: Plyr = null;
  hls: Hls = null;


  currentLiveId :number = null;
  detailIndex : number = null;
  showVideo: boolean = false;


  /* constructors */
  constructor(
    private tvfAuthService: TvfAuthService,
    private epgService: EpgService,
    private hlsStreamPipe: HlsStreamPipe,
    private ngZone: NgZone
  ) { 
    if(this.epgService.isCurrentDateDST()) {
      this.m_offsetDST = 60 * 60 * 1000;
      this.utcOffset = 'UTC+02:00';
    }
  }


  /* methods */
  ngOnInit(): void {
    // ensure that access token is present in the http headers
    this.tvfAuthService.checkAutoLogin();

    this.setCurrentPosition();
    this.source.subscribe((value: number) => {
      this.setCurrentPosition();
    });

    this.initChannelList();
    // this.initEpgGrid();
  }

  ngOnDestroy() {
    console.log("destroy");
    this.closeStream();
  }

  /**
   * Workaound: we cant not use ngAfterView.
   */
  ngAfterViewChecked(): void {
    if (!this.blInitViewPort) {
      this.initViewPort();
      this.blInitViewPort = true;
    }
  }

  initViewPort(): void {
    // this.epgWrapperRef.nativeElement.scrollTo({
    //   left: this.currentPosition - (TimeDifferenceWidthPipe.VIEWPORT_WIDTH / 2), behavior: 'smooth'
    // });
    this.epgWrapperRef.nativeElement.scrollLeft = this.currentPosition - (TimeDifferenceWidthPipe.VIEWPORT_WIDTH / 2);
  }

  setCurrentPosition(): void {
    const currentDate = new Date();
    const currentMinutes = new Date(currentDate.getTime() - this.m_offsetDST).getHours() * 60 + currentDate.getMinutes() ;
    this.updateCurrentTime(currentDate);
    this.currentPosition = ((currentMinutes / TimeDifferenceWidthPipe.SMALL_UNIT_IN_MIN)
      * TimeDifferenceWidthPipe.SMALL_UNIT_IN_PX + this.offset);

    // for (let obj of this.channelLiveList) {
    //   this.flagAsCurrentChannel(currentDate, obj, FlagChannelMode.UPDATE);
    // }

  }
  
  playLiveChannel(index:number):void {
    // console.log("index", index);
    // console.log("channel", this.channelLiveList[index]);
    this.initViewPort();
    this.showChannel(index, this.channelLiveList[index]);
  }

  updateCurrentTime(currentDate: Date): void {
    const minutesLabel = (currentDate.getMinutes() < 10 ? '0' : '') + currentDate.getMinutes();
    this.currentLabel = (new Date(currentDate.getTime())).getHours() + ':' + minutesLabel;

  }

  // private initEpgGrid(): void {
  //   const dCurrentDate = new Date();
  //   const myFormattedDate = this.epgService.formatDateShort(dCurrentDate);
  //   this.epgService
  //     .getEpgOverviewList(dCurrentDate)
  //     .subscribe((retValue: EpgListResponse) => {
  //       const tmpEpgMap = retValue.data.reduce((map, obj) => {
  //         if (map[obj.live_id] == null) {
  //           map[obj.live_id] = [];
  //         }
  //         if (!obj.start_time.startsWith(myFormattedDate)) {
  //           obj.start_time = myFormattedDate + 'T00:00:00Z';
  //         }

  //         if (!obj.end_time.startsWith(myFormattedDate)) {
  //           obj.end_time = myFormattedDate + 'T23:59:59Z';
  //         }

  //         this.flagAsCurrentChannel(dCurrentDate, obj, FlagChannelMode.INSERT);

  //         map[obj.live_id].push(obj);
  //         return map;
  //       }, {} as Map<number, ChannelProgram>);

  //       const tmpEpgArray = Object.values(tmpEpgMap);
  //       tmpEpgArray.sort(this.sortChannelArrayByLiveId.bind(this));
  //       this.channelLiveList.sort(this.sortChannelByLiveId.bind(this));

  //       this.epgDataList = Object.values(tmpEpgArray);
  //     });

  // }

  private sortChannelByLiveId(a: ChannelProgram, b: ChannelProgram)  {
    return this.channelSordedIds.indexOf(a.live_id) 
            < this.channelSordedIds.indexOf(b.live_id) ? -1 : 1;
  }

  private sortChannelArrayByLiveId(a: Array<ChannelProgram>, b: Array<ChannelProgram>)  {
    return this.channelSordedIds.indexOf(a[0].live_id) 
            < this.channelSordedIds.indexOf(b[0].live_id) ? -1 : 1;
  }

  flagAsCurrentChannel(
    dCurrentDate: Date,
    epgProgram: ChannelProgram,
    mode: FlagChannelMode
  ): void {
    const dStartTime = new Date(epgProgram.start_time);
    const dEndTime = new Date(epgProgram.end_time);

    const now = dCurrentDate.getTime();
    if (now >= dStartTime.getTime() && now < dEndTime.getTime()) {
      this.channelLiveList.push(epgProgram);
    }
    //  else if (FlagChannelMode.UPDATE === mode) {
    //   // // remove from current channel list
    //   // const idx = this.channelLiveList.indexOf(epgProgram);
    //   // this.channelLiveList.splice(idx, idx);
    //   // console.log("Remove: ", idx);

    //   // const arrEpgChannel = this.dataEpgMap[epgProgram.live_id];
    //   // const newIdx = arrEpgChannel.indexOf(epgProgram) + 1;
    //   // console.log("New: ", idx);
    //   // if (newIdx < arrEpgChannel.length) {
    //   //   this.channelLiveList.push(arrEpgChannel[newIdx]);
    //   // }
    // } else {
    // }
  }


  initChannelList(): void {
    this.epgService
        .getTvChannelListOrdered()
        .subscribe((retValue: AssetsListResponse) => {
      // Init channel Map and create channel-sorted-ids array for later usage
      this.channelMap = retValue.data.reduce((map, obj) => {
        this.channelSordedIds.push(obj.id);
        map[obj.id] = obj;
        return map;
      }, {});

      var idBulks:string[] = [];
      for (var i=0,iter=Math.ceil(this.channelSordedIds.length/10); i < iter; i++){
        idBulks.push(this.channelSordedIds.slice(i*10, (i+1)*10).join(","));
      }
      const dCurrentDate = new Date();
      const myFormattedDate = this.epgService.formatDateShort(dCurrentDate);
      this.epgService
          .getEpgOverviewListByBulks(idBulks, dCurrentDate)
          .subscribe((retValue: EpgListResponse) => {
            const tmpEpgMap = retValue.data.reduce((map, obj) => {
              if (map[obj.live_id] == null) {
                map[obj.live_id] = [];
              }
              if (!obj.start_time.startsWith(myFormattedDate)) {
                obj.start_time = myFormattedDate + 'T00:00:00Z';
              }
    
              if (!obj.end_time.startsWith(myFormattedDate)) {
                obj.end_time = myFormattedDate + 'T23:59:59Z';
              }
    
              this.flagAsCurrentChannel(dCurrentDate, obj, FlagChannelMode.INSERT);
    
              map[obj.live_id].push(obj);
              return map;
            }, {} as Map<number, ChannelProgram>);

            const tmpEpgArray = Object.values(tmpEpgMap);
            tmpEpgArray.sort(this.sortChannelArrayByLiveId.bind(this));
           
            this.channelLiveList.sort(this.sortChannelByLiveId.bind(this));

            var tmpArray = this.epgDataList;
            Array.prototype.push.apply(tmpArray, Object.values(tmpEpgArray));
            tmpArray.sort(this.sortChannelArrayByLiveId.bind(this));

            this.epgDataList = tmpArray;
          });

    });
  }

  showChannel(
    index: number,
    epgChannel: ChannelProgram
  ): void {
    this.closeStream();

    this.currentLiveId = epgChannel.live_id;  
    this.detailIndex = index;  

    if (
      epgChannel.catchup === true ||
      this.channelLiveList.indexOf(epgChannel) !== -1
    ) {

      // :INFO: Run the plyr.js init stuff outside of Angular, therefore it is
      // inovked imediately and not after all already queued events.
      this.ngZone.runOutsideAngular(() => {
        const video = document.getElementById('video_' + index) as HTMLVideoElement;
        const channel = this.channelMap[epgChannel.live_id];
        this.initPlyr(
          channel,
          epgChannel,
          video, 
        );
      });
      this.showVideo = true;
    } else {
      this.showVideo = false;
    }
    this.initEpg(epgChannel);
  }

  closeChannel(): void {
    this.closeStream();
    this.currentLiveId = null;
  }

  closeStream(): void {
    // if (this.player != null) {
    //   this.player.stop();
    //   this.player.destroy();
    //   this.player = null;
    // }

    if (this.hls != null) {
      this.hls.stopLoad();
      this.hls.destroy();
      this.hls = null
    }
  }

  private initEpg(
    epgChannel: ChannelProgram
  ): void {
    this.epgService
      .getLiveEventAt(
        epgChannel.live_id,
        epgChannel.start_time
      )
      .subscribe((value: EpgListResponse) => {
        this.epgCurrentList = value.data;
      });
  }

  private initPlyr(
    assetItem: AssetItem,
    epgChannel: ChannelProgram,
    video: HTMLVideoElement
  ): void {
    const rHlsStream = this.hlsStreamPipe.transform(assetItem.resources);
    const defaultOptions:any = {};

    console.log("HLS-Stream", rHlsStream);
    var controlsToShow = [
      'play-large', // The large play button in the center
      'play', // Play/pause playback
      // 'duration', // The full duration of the media
      'volume', // Volume control
      'mute', // Toggle mute
      'captions', // Toggle captions
      'settings', // Settings menu
      'fullscreen', // Toggle fullscreen
    ];

    if (epgChannel.catchup === true) {
      controlsToShow.splice(4,0,'progress');
      controlsToShow.splice(5,0,'duration');
      controlsToShow.splice(2,0, 'fast-forward');
    }
    
    if (!Hls.isSupported()) {
      alert('HLS is NOT supported');
    } else {
      this.subcribeToStream(
        assetItem,
        epgChannel
      ).subscribe((response: DataStreamResponse) => {
        // video.getElementsByTagName("source")[0].src=response.data;
        // https://github.com/sampotts/plyr/issues/1741
        const hlsOptions = { 
          xhrSetup: xhr => { },
          enableWorker: true,
          lowLatencyMode: true,
          backBufferLength: 90,
          allowedBufferedRangesInSeekTest: 3
        };
        this.hls = new Hls(hlsOptions);
        this.hls.loadSource(response.data);

        this.hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
          const availableQualities = this.hls.levels.map((l) => l.bitrate);

          
          // defaultOptions.quality = {
          //   // default: availableQualities[0], //Default - AUTO
          //   options: availableQualities,
          //   // forced: true,        
          //   onChange: (e) => this.updateQuality(e),
          // }

          this.player = new Plyr(video, {
            captions: {
              active: true,
              update: true,
              language: 'en'
            },
            controls: controlsToShow,
            autoplay: true,
            // quality: defaultOptions.quality, 
            speed: { 
              selected: 1, 
              options: 
              [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] 
            }
          });
          this.hls.attachMedia(video);     
          this.player.play();
        });
      });
    }
  }

  private subcribeToStream(
    assetItem: AssetItem,
    epgChannel: ChannelProgram
  ): Observable<DataStreamResponse> {
    if (epgChannel.catchup === true) {
      return this.epgService.getCatchupStream(epgChannel.id);
    } else {
      const rHlsStream = this.hlsStreamPipe.transform(assetItem.resources);
      return this.epgService.getChannelStream(assetItem.id, rHlsStream.id);
    }
  }

  updateQuality(newQuality) {
    this.hls.levels.forEach((level, levelIndex) => {
      if (level.bitrate === newQuality) {
        console.log("Found quality match with " + newQuality);
        this.hls.currentLevel = levelIndex;
      }
    });
  }
}
