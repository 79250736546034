/**
 * @author Johann Kowalski (traal-devel) <devel@traal.ch>
 */


import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TvfAuthService } from '../../service/tvf-auth.service';

@Injectable()
export class XAuthTokenInterceptor implements HttpInterceptor {

  /* member variables */

  /* constructors */
  constructor(
    private tvfAuthService: TvfAuthService
  ) { }

  /* methods */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.tvfAuthService.accessToken != null) {

      const authReq = request.clone({
        headers: request.headers.set('X-Auth-Token', this.tvfAuthService.accessToken)
      });
      return next.handle(authReq);
    }

    return next.handle(request);
  }
}
